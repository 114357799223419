@charset "utf-8";
@use "../../global/_" as g;

.c-entry {
	&__head {
		display: flex;
		flex-wrap: wrap;

		.__time {
			margin: 15px 15px 0 0;
			color: #949494;
		}

		.e-label {
			margin: 15px 15px 0 0;
		}

		.__ttl {
			width: 100%;
			margin: 25px 0 0 0;
		}
	}

	&__img-column2 {
		display: flex;
		justify-content: space-between;

		& > * {
			margin: 0 10px;
		}
	}

	&__block {
		margin: 40px 0 0;

		img {
			max-width: 100%;
			margin: 40px auto 0;
		}

		strong {
			font-weight: g.$font-bold;
		}

		em {
			font-style: italic;
		}

		p {
			margin: 40px 0 0;

			&.e-note {
				margin: 10px 0 0;
			}
		}

		.c-grid img {
			margin-top: 10px;
		}

		.c-float img {
			margin-top: 0;
		}

		.e-btn-arrow {
			margin: 40px 0 0 0;
		}

		figure {
			img {
				margin: 0;
			}
		}

		.e-list-number,
		.e-list-circle {
			p:first-child {
				margin: 0;
			}
		}
	}
}

@include g.media-m-ov {
	.c-entry {
		&__block {
			a img {
				@include g.transition;

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}

@include g.media-s {
	.c-entry {
		&__head {
			.__time {
				margin: 10px 10px 0 0;
				width: 100%;
			}

			.e-label {
				margin: 10px 10px 0 0;
			}
		}

		&__img-column2 {
			&._sp-col1_ {
				display: block;

				& > * {
					margin: 0 0 15px;
				}
			}
		}

		&__block {
			margin: 30px 0 0;

			p {
				margin: 25px 0 0 0;

				&.e-note {
					margin: 10px 0 0;
				}
			}

			img {
				max-width: 100%;
				margin: 25px 0 0 0;
			}

			.e-btn-arrow {
				margin: 25px 0 0 0;
			}
		}
	}
}
