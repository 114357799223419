@charset "utf-8";
@use "../../global/_" as g;
.e-h6 {
	margin: 30px 0 0 0;
	font-size: g.$fz-l;
	@include g.font-medium;
	color: g.$baseColor;
    &._black_ {
        color: g.$text-black;
    }
} 

@include g.media-s {
	.e-h6 {
		font-size: g.$fz-m;
	}
}
