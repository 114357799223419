@charset "utf-8";
@use "../../global/_" as g;
.e-h5 {
	margin: 40px 0 0 0;
	padding: 0 0 15px 0;
	border-bottom: 1px solid g.$line-gray;
	font-size: g.$fz-xl;
	line-height: 1.4;
	@include g.font-medium;
}

@include g.media-s {
	.e-h5 {
		margin: 20px 0 0 0;
		padding: 0 0 10px 0;
		font-size: g.$fz-l;
	}
}
