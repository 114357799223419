@charset "utf-8";
@use "../../global/_" as g;
.e-label {
	min-width: 100px;
	height: 25px;
	color: g.$white;
	font-size: g.$fz-xs;
	text-align: center;
	line-height: 25px;
	background: g.$baseColor;
    padding: 0 10px;
    &._topic_ {
		background: g.$white;
        border: 1px solid g.$text-gray;
        color: g.$text-gray;
	}
	&._info_ {
        background: g.$baseColor;
    }
    &._international_ {
        background: #E47E09;
    }
    &._club_ {
        background: #C11F1F;
    }
    &._examination_ {
        background: #4E8FC1;
    }
	&._club-name_ {
		background: g.$white;
        border: 1px solid g.$text-gray;
        color: g.$text-gray;
	}
	&._h-s_ {
		height: 22px;
		line-height: 22px;
	}
	&._zaiok_ {
		width: auto;
		padding: 0 20px;
		margin: 0 10px 0 0;
	}
}

@include g.media-s {
	.e-label {
		._student_ & {
			min-width: 70px;
		}
	}
}
