@charset "utf-8";
@use "../../global/_" as g;
.e-figure {
	display: table;
	margin-inline: auto;

	img {
		display: block;
		max-width: 100%;
		margin-inline: auto;
	}
	figcaption {
		display: table-caption;
		caption-side: bottom;
		margin-top: g.$m-xxs;

		&._al-c_ {
			text-align: center;
		}
	}

	&:is(.c-entry-body > *) {
	}
}