@charset "utf-8";
@use "../../global/_" as g;
.t-wrap {
	position: relative;
	color: g.$text-black;
	font-size: g.$fz-m;
	line-height: 1.8;
}
@include g.media-m-ov {
	.t-wrap {
		padding-right: 280px;
		.index & {
			padding-right: 0;
		}
		.index._gnav-show_ & {
			transition: padding-right 0.5s ease;
			padding-right: 280px;
		}
	}
}
@include g.media-m {
	.t-wrap {
		padding-right: 0;
		.index._gnav-show_ & {
			padding-right: 0;
		}
		._gnav-show_ & {
			padding-right: 0;
			position: fixed;
			width: 100%;
			height: 100%;
			&::before {
				content: "";
				transition: opacity 0.5s 0.1ms;
				opacity: 1.0;
				position: fixed;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.7);
				z-index: 3;
			}
		}
	}
}
@include g.media-s {
	.t-wrap {
		font-size: 1.5rem;
		line-height: 1.6;
		._gnav-show_ & {
			width: 100%;
			height: 100%;
		}
	}
}
