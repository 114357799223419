@charset "utf-8";
@use "../../global/_" as g;
.e-note {
	font-size: g.$fz-s;
	margin: 10px 0 0 0;
	line-height: 1.5;
}

@include g.media-s {
	.e-note {
		font-size: g.$fz-xs;
	}
}
