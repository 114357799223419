@charset "utf-8";
@use "../../../global/_" as g;
.e-form-radio {
	.__elem {
		display: none;
		&:checked + .__txt {
			&::after {
				opacity: 1;
			}
		}
	}
	
	.__txt {
		position: relative;
		padding: 0 0 0 20px;
		cursor: pointer;
		&::before {
			left: 0;
			border: 2px solid #d9d9d9;
			content: '';
			@include g.centering-elements(false, true);
			@include g.circle(14);
		}
		&::after {
			left: 5px;
			background: g.$baseColor;
			content: '';
			opacity: 0;
			@include g.centering-elements(false, true);
			@include g.circle(8);
		}
	}
}
