@charset "utf-8";
@use "../../global/_" as g;
.e-catch01 {
	margin: 65px 0 0;
	font-size: 3.4rem;
	line-height: 1.5;
	@include g.font-bold;
    position: relative;
    padding: 0 0 0 50px;
    &::before {
        color: g.$baseColor;
        content: "";
        background: url("/common/img/common/icon_ citation.svg") no-repeat;
        width: 29px;
        height: 21px;
        position: absolute;
        left: 0;
        top: 10px;
    }
}

@include g.media-s {
	.e-catch01 {
		margin: 20px 0 15px;
        padding: 0 0 0 30px;
		font-size: g.$fz-xxl;
        &::before {
            width: 18px;
            height: 14px;
            background-size: cover;
        }
    }
}
