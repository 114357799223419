@charset "utf-8";
@use "../../../global/_" as g;

.c-index-news {
    background: url(/common/img/index/bg_news.svg) repeat;
    &Head {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &__category {
            flex: 1 1 auto;
            display: flex;
            justify-content: flex-end;
        }
        &__filter {
            display: flex;
            justify-content: center;
        }
        &__item {
            position: relative;
            color: g.$text-black;
            border: 1px solid g.$line-gray;
            text-decoration: none;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: g.$white;
            padding: 0 20px;
            border-collapse: collapse;
            border-right: none;
            @include g.transition;
            &::before {
                content: "";
                border-radius: 50%;
                width: 6px;
                height: 6px;
                background: g.$white;
                margin: 0 5px 0 0;
            }
            &._all_::before {
                display: none;
            }
            &._info_::before {
                background: g.$baseColor;
            }
            &._international_::before {
                background: #E47E09;
            }
            &._club_::before {
                background: #C11F1F;
            }
            &._examination_::before {
                background: #4E8FC1;
            }
            &._active_ {
                background: g.$baseColor;
                color: g.$white;
                &::before {
                    background: g.$white;
                }
                &._international_ {
                    background: #E47E09;
                }
                &._club_ {
                    background: #C11F1F;
                }
                &._examination_ {
                    background: #4E8FC1;
                }
            }
        }
    }
}
@include g.media-m-ov {
    .c-index-news {
        &Head {
            &__item {
                &:hover {
                    background: g.$baseColor;
                    color: g.$white;
                    &::before {
                        background: g.$white;
                    }
                    &._international_ {
                        background: #E47E09;
                    }
                    &._club_ {
                        background: #C11F1F;
                    }
                    &._examination_ {
                        background: #4E8FC1;
                    }
                }
                &:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
                &:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-right: 1px solid g.$line-gray;
                }
            }
        }
        &Body {
            margin: 45px -10px 0;
        }
    }
}
@include g.media-s {
	.c-index-news {
		&Head {
            &__filter {
                flex-wrap: wrap;
                justify-content: flex-start;
                border-top: 1px solid g.$line-gray;
            }
            &__item {
                width: 50%;
                border: none;
                border-bottom: 1px solid g.$line-gray;
                border-right: 1px solid g.$line-gray;
                &:nth-child(odd) {
                    border-left: 1px solid g.$line-gray;
                }
            }
		}
		&Body {
			margin: 30px -5px 0;
		}
	}
}
