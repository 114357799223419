@charset "utf-8";
@use "../../global/_" as g;
.c-pagetop {
	position: fixed;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction:column; 
		width: 50px;
		height: 50px;
		background: rgba(#E5EDF9, 0.8);
		color: g.$baseColorDarken;
		font-size: g.$fz-xxxs;
		text-decoration: none;
		line-height: 1.0;
		@include g.transition;
		
		&:hover {
			@include g.opacity;
		}
		.__txt {
			margin-top: 5px;
			@include g.font-normal;
			font-size: 0.9rem;
		}
	}
}
@include g.media-m-ov {
	.c-pagetop {
		right: 280px;
		bottom: 0;
	}
}

@include g.media-m {
	.c-pagetop {
		right: 0;
	}
}

@include g.media-s {
	.c-pagetop {
		z-index: 3;
		right: 0;
		bottom: 50px;
	}
}
