@charset "utf-8";
@use "../../global/_" as g;
.c-about {
    &Philosophy {
        &__item {
            display: flex;
            .__label {
                @include g.font-bold;
                min-width: 180px;
                position: relative;
                font-size: 1.7rem;
                padding: 0 70px 0 0;
                margin: 0 30px 0 0;
                white-space: nowrap;
                &::after {
                    width: 50px;
                    position: absolute;
                    top: 0.8em;
                    right: 0;
                    height: 1px;
                    content: "";
                    border-bottom: 1px solid #D4D4D4;
                }
            }
        }
    }
}
@include g.media-m-ov {
    .c-about {
        &Message {
            &__img {
                position: relative;
                height: 0;
                padding-bottom: 33.15%;
                margin: 60px 0 0;
                .__main {
                    position: absolute;
                }
                .e-catch01 {
                    @include g.centering-elements(false, true);
                    left: 30px;
                    margin: 0;
                }
            }
        }
    }
}

@include g.media-m {
    .c-about {
        &Philosophy {
            &__item {
                .__label {
                    min-width: 160px;
                }
            }
        }
    }
}
@include g.media-s {
    .c-about {
        &Philosophy {
            &__item {
                display: block;
                position: relative;
                padding: 0 0 0 20px;
                &::before {
                        width: 10px;
                        position: absolute;
                        top: 0.8em;
                        left: 0;
                        height: 1px;
                        content: "";
                        border-bottom: 1px solid #D4D4D4;
                    }
                .__label {
                    width: 100%;
                    display: block;
                    margin: 0 0 5px 0;

                    &::after {
                        display: none;
                    }
                }
            }
        }
        &Message {
            &__img {
                margin: 30px 0 0;
            }
            &__catch {
                background: #F5F5F5;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 25px 0 0 0;
                padding: 15px;
            }
        }
    }
}
