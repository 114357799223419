@charset "utf-8";
@use "../../global/_" as g;
body {
	@include g.font-normal;
}
a {
	color: g.$text-black;
	text-decoration: underline;
	
	.icon-filter_none::before,
	.icon-file-pdf-o::before,
	.icon-file-excel-o::before,
	.icon-file-word-o::before,
    .icon-external-link::before {
		display: inline-block;
		
		@include g.iehack {
			height: 1em;
			overflow-y: hidden;
		}
	}
}
sup {
	vertical-align: super;
}
input,
textarea {
	appearance: none;
	max-width: 100%;
}
input::placeholder {
	opacity: .5;
}
button,
input[type="button"],
input[type="submit"] {
	appearance: none;
}
.icon-filter_none {
	margin: 0 3px;
	color: g.$baseColor;
}
.icon-file-pdf-o,
.icon-file-excel-o,
.icon-file-word-o {
	margin: 0 5px;
	color: g.$baseColor;	
}
.icon-external-link {
    margin: 0 5px;
}
.icon-file-pdf-o {
    color: g.$red;
}
.icon-file-excel-o {
    color: #107c41;
}
[class^="icon-"] .__hidden,
[class*=" icon-"] .__hidden,
.material-icons .__hidden {
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    position: absolute;
    top: -999999px;
    left: -999999px;
    width: 1px;
    height: 1px;
    font-size: 1px;
    text-indent: -999999px;
}

@include g.media-m-ov {
	html {
		overflow-y: scroll;
	}
    p {
        margin: 15px 0 0;
    }
	a {
		&:hover {
			text-decoration: none;
			color: g.$baseColor;
		}
		&._opacity_ {
			&:hover {
				img {
					@include g.transition;
					opacity: 0.7;
				}
			}
		}
		&[href^="tel:"] {
			color: g.$text-black;
			text-decoration: none;
			pointer-events: none;
		}
	}
}

@include g.media-m {
	html {
		font-size: calc(100vw / 102.4);
		._gnav-show_ & {
			overflow: hidden;
		}
	}
}

@include g.media-s {
	html.iziModal-isOverflow {
		overflow: inherit;
	}
	body {
		&._gnav-show_ {
			overflow-y: hidden;
		}
	}
    p {
        margin: 10px 0 0;
    }
	input,
	select,
	textarea {
		font-size: g.$fz-m !important;
	}	
}