@charset "utf-8";
@use "../../global/_" as g;
@include g.media-m-ov {
	.c-fix-menu {
		position: fixed;
		top: 50%;
		right: 0;
		display: flex;
		flex-direction: column;
		transform: translateY(-50%);
		z-index: 5;

		&__btn {
			display: flex;
			flex-direction: column;
			width: 50px;
			padding: 20px 0;
			@include g.bg-gradation-vertical(true);
			color: g.$white;
			font-size: 1.5rem;
			text-align: center;
			text-decoration: none;
			line-height: 50px;		
			@include g.transition;
			@include g.font-normal;
			border-top: 1px solid g.$line-gray;
			&:first-child {
				border-top: 0;
				border-top-left-radius: 5px;
			}
			&:nth-child(3) {
				border-bottom-left-radius: 5px;
			}
			&:hover {
				background: g.$baseColorDarken;
				color: g.$white;
			}
			&._icon_ {
				background: g.$white;
				border-top: 1px solid g.$line-gray;
                color: g.$text-black;
                font-size: 1.0rem;
                line-height: inherit;
				&:hover {
					background: g.$baseColor;
                    color: g.$white;
                    .__ico {
                        color: g.$white;
                    }
				}
                .__ico {
                    color: g.$baseColor;
                }
			}
            &._sns_ {
                display: none;
            }
		}
		&__text {
			writing-mode: vertical-rl;
			transform: rotate(0.001deg);
			.__num {
				text-combine-upright: all;
			}
		}	
	}
}
@include g.media-s {
	.c-fix-menu {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 5;
		display: flex;
		height: 50px;
		justify-content: center;
		width: 100%;
		&__btn {
			display: flex;
			align-items: center;
			justify-content: center;
			@include g.bg-gradation-side(true);
			color: g.$white;
			font-size: 1.5rem;
			text-align: center;
			text-decoration: none;	
			@include g.font-normal;
			border-left: 1px solid #2C3974;
			width:  30%;
			&:first-child {
                position: relative;
				border-left: 0;
				flex: 0 0 40%;
			}
            &._txt_ {
                .__ico {
                    font-size: g.$fz-m;
                    @include g.centering-elements(false, true);
                    right: 5px;
                }
            }
			&._icon_ {
				flex: 1 1 auto;
                border-left: none;
				border-right: 1px solid g.$line-gray;
                flex-direction: column;
                font-size: 1.1rem;
                background: g.$white;
                color: g.$text-black;
                .__ico {
                    font-size: g.$fz-l;
                    display: block;
                    color: g.$baseColor;
                }
			}
            &:nth-child(3) {
                border-right: none;
            }
            &._sns_ {
                background: #333333;
                flex: 0 0 50px;
            }
		}
	}
}
