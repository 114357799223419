@charset "utf-8";
@use "../../global/_" as g;
.c-breadcrumb {
	margin: 0 0 20px;
	font-size: g.$fz-xs;
	line-height: 1.4;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1240px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__item {
        color: g.$white;
		&:not(:last-child) {
			text-decoration: none;
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
	&__link {
		color: g.$white;
        text-decoration: underline;
	}
}
@include g.media-s {
	.c-breadcrumb {
		margin: 0 0 10px;
		font-size: g.$fz-xxs;
	
		&__inner {
			padding: 0 15px;
		}
	}
}
