@charset "utf-8";
@use "../../../global/_" as g;
.u-pa-xxxs {
	padding: g.$m-xxxs !important;

	@include g.media-s {
		&._sm-pa-0_    { padding: 0 !important; }
		&._sm-pa-xxxs_ { padding: g.$m-xxxs !important; }
		&._sm-pa-xxs_  { padding: g.$m-xxs !important; }
		&._sm-pa-xs_   { padding: g.$m-xs !important; }
		&._sm-pa-s_    { padding: g.$m-s !important; }
		&._sm-pa-m_    { padding: g.$m-m !important; }
		&._sm-pa-l_    { padding: g.$m-l !important; }
		&._sm-pa-xl_   { padding: g.$m-xl !important; }
		&._sm-pa-xxl_  { padding: g.$m-xxl !important; }
		&._sm-pa-xxxl_ { padding: g.$m-xxxl !important; }
	}
}
