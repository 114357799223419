@charset "utf-8";
@use "../../global/_" as g;
.c-foot-catch {
	display: block;
	background: url("/common/img/common/bg_100th.png") no-repeat;
	background-size: cover;
	height: 280px;
	&__inner {
		height: 100%;
		max-width: 1240px;
		padding: 0 20px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__logo {
		flex: 1 1 auto;
		.__elem {
			max-width: 100%;
		}
	}
	&__link {
		flex: 0 0 auto;
		white-space: nowrap;
		margin: 0 0 0 30px;
		&__btn {
			text-decoration: none;
			background: g.$white;
			padding: 0 30px 0 20px;
			border-radius: 25px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: g.$text-purple;
			position: relative;
			.__ico {
				@include g.centering-elements(false, true);
				right: 10px;
				font-size: g.$fz-s;
			}
		}
	}
}
@include g.media-m-ov {
	.c-foot-catch {
		&__link {
			&__btn {
				@include g.transition;
				&:hover {
					background: g.$text-purple;
					color: g.$white;
				}
			}
		}
	}
}
@include g.media-s {
	.c-foot-catch {
		background: url("/common/img/common/bg_100th_sp.png") no-repeat;
		background-size: contain;
		height: 0;
		padding-bottom: 98.67%;
		position: relative;
		&__inner {
			padding: 0 15px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			position: absolute;
			top: 0;
		}
		&__logo {
			flex: initial;
			text-align: center;
			.__elem {
				max-width: 80%;
			}
		}
		&__link {
			margin: 30px 0 0 0;
		}
	}
}