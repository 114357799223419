@charset "utf-8";
@use "../../global/_" as g;
.e-h1 {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 400px;
	background-image: url(/common/img/common/bg_ttl01.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
    position: relative;
    &__inner {
        max-width: 1240px;
        padding: 0 20px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        align-items: flex-end;
        .__ttl {
            font-size: 5.0rem;
            line-height: 1.0;
            color: g.$white;
            @include g.font-bold;
            position: relative;
            &::after {
                font-size: g.$fz-m;
                content: "/";
                margin: 0 0 0 20px;
                @include g.font-poppins-medium;
            }
        }
        .__en {
            color: g.$white;
            @include g.font-poppins-medium;
            font-size: g.$fz-m;
            content: "/";
            line-height: 1.0;
            margin: 0 0 0 20px;
        }
    }
    &._about_ {
		background-image: url(/common/img/about/bg_ttl01.png);
	}
    &._education_ {
		background-image: url(/common/img/education/bg_ttl01.png);
	}
    &._career_ {
		background-image: url(/common/img/career/bg_ttl01.png);
	}
    &._school_life_ {
		background-image: url(/common/img/school_life/bg_ttl01.png);
	}
    &._club_ {
		background-image: url(/common/img/club/bg_ttl01.png);
	}
    &._entrance_ {
		background-image: url(/common/img/entrance/bg_ttl01.png);
	}
    &._contact_ {
		background-image: url(/common/img/contact/bg_ttl01.png);
	}
    &._sensei_ {
		background-image: url(/common/img/sensei/bg_ttl01.png);
	}
    &._middle_school_ {
		background-image: url(/common/img/middle_school_teacher/bg_ttl01.png);
	}
    &._x_ {
		background-image: url(/common/img/x/bg_ttl01.png);
	}
    &._teaching_paractise_ {
		background-image: url(/common/img/educational_practice_inquiry/bg_ttl01.png);
	}
    &._topic_ {
		background-image: url(/common/img/topic/bg_ttl01.png);
	}
    &._blog_ {
		background-image: url(/common/img/blog/bg_ttl01.png);
	}
    &._student_ {
		background-image: url(/common/img/student/bg_ttl01.png);
	}
	&._recruit_ {
		background-image: url(/common/img/recruit/bg_ttl01.png);
	}
}

@include g.media-s {
	.e-h1 {
		height: 220px;
		&__inner {
            display: block;
            padding: 10px 15px 0;
            .__ttl {
                font-size: 3.2rem;
                &::after {
                    display: none;
                }
            }
            .__en {
                margin: 15px 0 0 0;
                display: block;
            }
        }
	}
}
