@charset "utf-8";
@use "../../global/_" as g;
@include g.media-m-ov {
	.l-row {
		padding-bottom: 100px;
		&__inner {
			max-width: 1240px;
			padding: 70px 20px 0;
			margin: 0 auto;
			&._narrow01_ {
				max-width: 1160px;
			}
			&._narrow02_ {
				max-width: 960px;
			}
		}
	}
}

@include g.media-m {
	.l-row {
		&__inner {
			padding: 50px 25px 0;
		}
	}
}
@include g.media-s {
	.l-row {
		padding-bottom: 60px;
		&__inner {
			max-width: 100%;
			padding: 40px 15px 0;
			margin: 0 auto;
		}
	}
}


