@charset "utf-8";
@use "../../global/_" as g;
.l-contents {	
}

@include g.media-m-ov {
	.l-contents {
		// 2カラムレイアウト-------------------------------
        &._col2_ .l-row {
            width: min(1240px, 100% - 40px);
	        margin: 70px auto 0;
            display: grid;
            grid-template-columns: 1fr 300px;
            grid-template-rows: auto;
            gap: 0 60px;
            grid-template-areas: "body side";
            @media screen and (max-width:1200px) {
                width: min(1240px, 100% - 40px);
                margin-top: 25px;
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: repeat(2, auto);
                gap: 60px 0;
                grid-template-areas:
                    "body"
                    "side";
            }
        }
        &._col2_ &__body { grid-area: body; }
        &._col2_ &__side { grid-area: side; }
	}
}


@include g.media-s {
	.l-contents {
	    margin: 0 0 30px;
        &._col2_ .l-row{
            width: min(1220px, 100% - 30px);
		    margin: 45px auto 0;
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: repeat(2, auto);
            gap: 50px 0;
            grid-template-areas:
                "body"
                "side";
        }
	}
}


