@charset "utf-8";
@use "../../global/_" as g;
.c-entrance {
	&__item {
		position: relative;
		margin: 40px 0 0 0;
		display: block;
		height: 250px;
		overflow-x: hidden;
		&:first-child {
			margin-top: 60px;
		}
		.__img {
			max-width: 100%;
			height: 100%;
			@include g.transition;
			object-fit: cover;
		}
		&__txt {
			position: absolute;
			left: 40px;
			bottom: 40px;
			.__label {
				background: g.$text-purple;
				color: g.$white;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				padding: 0 10px;
				border-radius: 13px;
				height: 26px;
				@include g.font-poppins-regular;
				font-size: g.$fz-xs;
			}
			.__ttl {
				margin: 5px 0 0 ;
				font-size: 3.0rem;
				@include g.font-bold;
				color: g.$white;
			}
		}
		.__ico {
			@include g.centering-elements(false, true);
			right: 40px;
			background: g.$text-purple;
			color: g.$white;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			width: 50px;
			height: 50px;
		}
	}
	
}
@include g.media-m-ov {
	.c-entrance {
		&__item {
			&:hover {
				@include g.transition;
				opacity: 0.7;
			}
		}
	}
}
@include g.media-s {
	.c-entrance {
		&__item {
			height: 150px;
			margin: 20px 0 0 0;
			&:first-child {
				margin-top: 30px;
			}
			.__img {
				width: 100%;
			}
			&__txt {
				left: 20px;
				bottom: 25px;
				.__label {
					border-radius: 13px;
					height: 26px;
				}
				.__ttl {
					margin: 10px 0 0 ;
					font-size: g.$fz-xxxl;
					@include g.font-bold;
					color: g.$white;
					line-height: 1.8rem;
				}
			}
			.__ico {
				right: 20px;
				width: 30px;
				height: 30px;
			}
		}
	}
}