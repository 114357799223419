@charset "utf-8";
@use "../../global/_" as g;
.c-topic-list {
	border-top: 1px solid #E7E7E7;
    margin: 60px 0 0 0;
    .club & {
        border-top: none;
        margin: 10px 0 0;
    }
    .index & {
        margin: 0;
    }
	&__item {
		border-bottom: 1px solid #E7E7E7;
        display: flex;
        align-items: center;
        padding: 30px 0;
	}
    &__link {
		color: g.$text-black;
        text-decoration: none;

        &:not(div) {
            .__txt {
                position: relative;
                color: g.$baseColor;
                text-decoration: underline;  
                padding-right: 25px;
                &::after {
                    @include g.centering-elements(false, true);
                    right: 0;
                    font-family: 'Material Icons';
                    content: "\e315";
                    color: #C2C2C2;
                    font-size: g.$fz-xl;
                    margin-top: 2px;
                }
            }
        }
        &[target="_blank"] {
			.__txt {
                &::after {
                    font-family: 'icomoon';
                    content: "\f08e";
                    margin: 0 0 0 5px;
                    color: #555555;
                    display: inline-block;
                    font-size: g.$fz-l;
                }
            }
        }
        &[href$=".pdf"] {
			.__txt {
                &::after {
                    font-family: 'icomoon';
                    content: "\f1c1";
                    margin: 0 0 0 5px;
                    color: g.$red;
                    display: inline-block;
                    font-size: g.$fz-l;
                }
            }
        }
	}
	.__date {
		flex: 0 0 auto;
		margin: 0 20px 0 0;
		line-height: 28px;
	}
    &__label {
        display: flex;
        margin: 0 15px 0 0;
        flex: 0 0 auto;
    }
	.__label {
		flex: 0 0 auto;
        margin: 0 10px 0 0;
	}
    .__txt {
        margin: 0;
    }
	&._student_ {
		.c-topic-list__item {
            flex-wrap: wrap;
		}
		.c-topic-list__head {
			display: flex;
			flex: 1 1 100%;
		}
		.__txt {
			flex: 1 1 100%;
            margin: 10px 0 0 0;
		}
	}
}
@include g.media-m-ov {
	.c-topic-list {
		&__link {
            &:not(div) {
                @include g.transition;
                &:hover {
                    color: g.$text-black;
                    .__txt {
                        text-decoration: none;
                    }
                }
            }
		}
	}
}

@include g.media-s {
	.c-topic-list {
        margin: 30px 0 0 0;
        .c-index-topic & {
            margin: 0;
        }
        &__item {
            flex-wrap: wrap;
            padding: 15px 0 20px;
        }
        .__date {
            margin-top: 5px;
        }
        &__label {
            display: flex;
            margin: 5px 0 0;
        }
        .__txt {
            flex: 1 1 100%;
            margin: 10px 0 0 0;
        }
	}
}
