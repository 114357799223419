@charset "utf-8";
@use "../../global/_" as g;
.e-h3 {
	margin: 80px 0 0;
	font-size: 3.0rem;
	line-height: 1.4;
	@include g.font-medium;
	position: relative;
	padding: 0 0 20px 0;
	border-bottom: 1px solid #DFDFDF;
    &::after {
        position: absolute;
        left: 0;
        bottom: -2px;
        height: 3px;
        width: 100px;
        background: g.$baseColor;
        content: "";
    }
    &._sub_ {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        color: g.$red;
        .__name {
            margin: 0 20px 0 0;
        }
        .__small {
            color: g.$text-black;
            font-size: g.$fz-xxxl;
        }
    }
    & + p {
        margin-top: 25px;
    }
}

@include g.media-s {
	.e-h3 {
		margin: 40px 0 0;
		font-size: g.$fz-xxxl;
		padding: 0 0 10px 0;
        &._sub_ {
            .__small {
                font-size: g.$fz-l;
            }
        }
	}
}
