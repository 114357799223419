@charset "utf-8";
@use "../../global/_" as g;
.m-accordion {
	cursor: pointer;
    position: relative;
	font-weight: g.$font-medium;
	@include g.transition;
    font-size: 1.9rem;
    border: 1px solid g.$line-gray;
    margin: 25px 0 0 0;
	&::after {
		@include g.transition;
		@include g.centering-elements(false, true);
		right: 15px;
		content: "\e313";
		font-size: 2.8rem;
        font-family: "Material Icons";
		color: g.$baseColor;
	}
	&._active_ {
        color: g.$baseColor;
		&::after {
			content: "\e316";
		}
	}
	&:hover {
		color: g.$baseColor;
		&::after {
			color: g.$baseColor;
		}
	}
	&._faq_ {
		padding: 15px 50px 15px 68px;
		position: relative;
		display: flex;
		align-items: center;
		&::before {
            margin: 0 15px 0 0;
			position: absolute;
			left: 15px;
			top: 15px;
			width: 38px;
			height: 38px;
            border-radius: 50%;
            @include g.font-poppins;
			content: "Q";
			background: g.$text-purple;
			color: g.$white;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: g.$fz-l;
		}
	}
	&__hide {
		display: none;
        border: 1px solid g.$line-gray;
        border-top: none;
        background: #F6F6F6;
		&._faq_ {
			padding: 25px 25px 25px 70px;
			position: relative;
			&::before {
				position: absolute;
				left: 15px;
				top: 25px;
				width: 38px;
			    height: 38px;
                border-radius: 50%;
				content: "A";
				background: g.$white;
				color: g.$text-purple;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: g.$fz-l;
				@include g.font-poppins;
			}
            p:first-child {
                margin-top: 0;
            }
		}
	}
}
@include g.media-s {
	.m-accordion {
		padding: 15px 30px 15px 20px;
		font-size: 1.5rem;
        &::after {
            right: 10px;
        }
        &._faq_ {
            padding: 15px 50px 15px 58px;
            &::before {
                left: 15px;
		    	top: 15px;
                width: 28px;
			    height: 28px;
                font-size: g.$fz-m;
            }
        }
        &__hide {
            &._faq_ {
    			padding: 20px 15px 20px 60px;
                &::before {
                    font-size: g.$fz-m;
                    left: 15px;
                    top: 20px;
                    width: 28px;
                    height: 28px;
                }
            }
        }
	}
}