@charset "utf-8";
@use "../../../global/_" as g;
.c-index-visual {
	position: relative;
	width: 100%;
	overflow-x: hidden;
	&__slider {
		width: 100%;
	}
	&__item {
		position: relative;
		height: 0!important;
		padding-bottom: 53.57%;
		overflow: hidden;
		min-height: inherit!important;
		.__elem {
			top: 0;
			left: 0;
			width: 100%;
		}
        &._video_ {
			.__elem {
				width: auto;
				@include g.centering-elements(true, true);
				min-height: 100%;
				min-width: 100%;
			}
		}
	}
	&__text {
		@include g.centering-elements(true, false);
        bottom: 20%;
		@include g.font-medium;
		color: g.$white;
        font-size: g.$fz-xl;
		line-height: 1.5;
		width: 100%;
		text-align: left;
        max-width: 1280px;
        padding: 0 20px;
        .__img {
            display: block;
            margin: 0 0 20px;
            max-width: 38.58%;
        }
	}
	&__100th {
		position: absolute;
		right: 100px;
		top: 10px;
		max-width: 94px;
		@include g.transition;
		&:hover {
			opacity: 0.7;
		}
		.__logo {
			width: 100%;
		}
	}
	.bx-wrapper {
		border: none;
		box-shadow: none;
		margin-bottom: 0;
		.bx-pager {
			max-width: 1160px;
			font-size: 0;
			line-height: 0;
			@include g.centering-elements(true, false);
			bottom: 80px;

			.bx-pager-item {
				width: 10px;
				height: 10px;
				margin: 0 5px;

				.bx-pager-link {
					width: 10px;
					height: 10px;
					padding: 0;
					border-radius: 0;
					background: g.$white;
					&:hover,
					&.active {
						background: g.$baseColor;
					}
				}
			}
		}
		.bx-next,
		.bx-prev {
			display: none;
		}
	}
}
@include g.media-s {
	.c-index-visual {
		&__text {
            bottom: 15%;
            text-align: center;
			font-size: g.$fz-m;
            .__img {
                max-width: 100%;
            }
		}
		&__100th {
			right: 70px;
			max-width: 60px;
		}
		&__item {
			position: relative;
			height: 0!important;
			padding-bottom: 153.33%;
			overflow: hidden;
			min-height: inherit!important;
			.__elem {
				top: 0;
				left: 0;
				width: auto;
			}
			&._video_ {
				.__elem {
					@include g.centering-elements(true, true);
				}
			}
		}
		.bx-wrapper {
            .bx-pager {
    			bottom: 50px;
                .bx-pager-item {
                    width: 15px;
                    height: 15px;

                    .bx-pager-link {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
		}
	}
}
