@charset "utf-8";
@use "../../global/_" as g;
.e-h4 {
	margin: 40px 0 0;
	padding: 3px 0 3px 15px;
	border-left: 4px solid g.$baseColor;
	font-size: g.$fz-xxl;
	line-height: 1.4;
	@include g.font-medium;
    
    & + .m-box,
    & + p,
    & + .e-list-circle {
        margin-top: 25px;
    }
}

@include g.media-s {
	.e-h4 {
		margin: 20px 0 0;
		padding: 2px 0 2px 10px;
		font-size: g.$fz-xl;
	}
}
