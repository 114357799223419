@charset "utf-8";
@use "../../../global/_" as g;
.u-mt-xxs {
	margin-top: g.$m-xxs !important;

	@include g.media-s {
		&._sm-mt-0_    { margin-top: 0 !important; }
		&._sm-mt-xxxs_ { margin-top: g.$m-xxxs !important; }
		&._sm-mt-xxs_  { margin-top: g.$m-xxs !important; }
		&._sm-mt-xs_   { margin-top: g.$m-xs !important; }
		&._sm-mt-s_    { margin-top: g.$m-s !important; }
		&._sm-mt-m_    { margin-top: g.$m-m !important; }
		&._sm-mt-l_    { margin-top: g.$m-l !important; }
		&._sm-mt-xl_   { margin-top: g.$m-xl !important; }
		&._sm-mt-xxl_  { margin-top: g.$m-xxl !important; }
		&._sm-mt-xxxl_ { margin-top: g.$m-xxxl !important; }
	}
}
