@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-pb-xxl {
	padding-bottom: g.$m-xxl !important;
	
	@include g.media-s {
		padding-bottom:math.div(g.$m-xxl, 2) !important;

		&._sm-pb-0_    { padding-bottom: 0 !important; }
		&._sm-pb-xxxs_ { padding-bottom: g.$m-xxxs !important; }
		&._sm-pb-xxs_  { padding-bottom: g.$m-xxs !important; }
		&._sm-pb-xs_   { padding-bottom: g.$m-xs !important; }
		&._sm-pb-s_    { padding-bottom: g.$m-s !important; }
		&._sm-pb-m_    { padding-bottom: g.$m-m !important; }
		&._sm-pb-l_    { padding-bottom: g.$m-l !important; }
		&._sm-pb-xl_   { padding-bottom: g.$m-xl !important; }
		&._sm-pb-xxl_  { padding-bottom: g.$m-xxl !important; }
		&._sm-pb-xxxl_ { padding-bottom: g.$m-xxxl !important; }
	}
}
