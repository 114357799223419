@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-pt-xxxl {
	padding-top: g.$m-xxxl !important;
	
	@include g.media-s {
		padding-top:math.div(g.$m-xxxl, 2) !important;

		&._sm-pt-0_    { padding-top: 0 !important; }
		&._sm-pt-xxxs_ { padding-top: g.$m-xxxs !important; }
		&._sm-pt-xxs_  { padding-top: g.$m-xxs !important; }
		&._sm-pt-xs_   { padding-top: g.$m-xs !important; }
		&._sm-pt-s_    { padding-top: g.$m-s !important; }
		&._sm-pt-m_    { padding-top: g.$m-m !important; }
		&._sm-pt-l_    { padding-top: g.$m-l !important; }
		&._sm-pt-xl_   { padding-top: g.$m-xl !important; }
		&._sm-pt-xxl_  { padding-top: g.$m-xxl !important; }
		&._sm-pt-xxxl_ { padding-top: g.$m-xxxl !important; }
	}
}
