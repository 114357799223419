@charset "utf-8";
@use "../../../global/_" as g;
.c-index-topic {
	.c-grid {
        .c-index-ttl01::before {
            margin-top: 0;
        }
    }
}

@include g.media-m-ov {
	.c-index-topic {
		
	}
}
@include g.media-s {
	.c-index-topic {
		&__head.__col._col-sm-12_ {
			margin-bottom: 0;
		}
		&__btn {
			margin: 15px auto 0;
			width: 60%;
			.e-btn-arrow {
				width: 100%;
			}
		}
	}
}
