@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-mr-xl {
	margin-right: g.$m-xl !important;

	@include g.media-s {
		margin-right:math.div(g.$m-xl, 2) !important;

		&._sm-mr-0_    { margin-right: 0 !important; }
		&._sm-mr-xxxs_ { margin-right: g.$m-xxxs !important; }
		&._sm-mr-xxs_  { margin-right: g.$m-xxs !important; }
		&._sm-mr-xs_   { margin-right: g.$m-xs !important; }
		&._sm-mr-s_    { margin-right: g.$m-s !important; }
		&._sm-mr-m_    { margin-right: g.$m-m !important; }
		&._sm-mr-l_    { margin-right: g.$m-l !important; }
		&._sm-mr-xl_   { margin-right: g.$m-xl !important; }
		&._sm-mr-xxl_  { margin-right: g.$m-xxl !important; }
		&._sm-mr-xxxl_ { margin-right: g.$m-xxxl !important; }
	}
}
