@charset "utf-8";
@use "../../global/_" as g;
.c-news-list {
	&__item {
		text-decoration: none;
		@include g.transition;
        background: g.$white;
        padding: 20px;
	}
	&__img {
		position: relative;
		padding-top: 68.97%;
		height: 0;
		overflow: hidden;
		background: g.$white;
		.__elem {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	&__body {
		width: 100%;
		padding: 15px 0 0;
		color: g.$text-black;
		line-height: 1.6;
		@include g.transition;
		position: relative;
		
		.__date {
			margin: 5px 0 0;
			display: block;
            color: #949494;
            font-size: 1.5rem;
		}
		.__text {
            font-size: 1.5rem;
			margin: 5px 0 0;
		}
	}
    &__label {
        display: flex;
        flex-wrap: wrap;
        .__label {
            margin: 5px 5px 0 0;
		}
    }
}
@include g.media-m-ov {
	.c-news-list {
		&__item {
			&:hover {
				text-decoration: none;
				box-shadow: 0 0 15px rgba(0,0,0,0.1);
				.__text {
					@include g.transition;
					color: g.$baseColor;
				}
			}
		}
	}
}
@include g.media-s {
	.c-news-list {
		&__item {
			flex-wrap: wrap;
			padding: 15px 0 0;
		}
		&__date {
			margin: 0 10px 0 0;
			font-size: g.$fz-xxs;
		}
		&__label {
			line-height: 1.2;
		}
		&__link {
			width: 100%;
			margin: 10px 0 0;
		}
		&__body {
			padding: 5px 0 0;
			.__text {
				&::before {
					color: g.$baseColor;
				}
			}
		}
	}
}
