@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-ml-xl {
	margin-left: g.$m-xl !important;
	
	@include g.media-s {
		margin-left:math.div(g.$m-xl, 2) !important;

		&._sm-ml-0_    { margin-left: 0 !important; }
		&._sm-ml-xxxs_ { margin-left: g.$m-xxxs !important; }
		&._sm-ml-xxs_  { margin-left: g.$m-xxs !important; }
		&._sm-ml-xs_   { margin-left: g.$m-xs !important; }
		&._sm-ml-s_    { margin-left: g.$m-s !important; }
		&._sm-ml-m_    { margin-left: g.$m-m !important; }
		&._sm-ml-l_    { margin-left: g.$m-l !important; }
		&._sm-ml-xl_   { margin-left: g.$m-xl !important; }
		&._sm-ml-xxl_  { margin-left: g.$m-xxl !important; }
		&._sm-ml-xxxl_ { margin-left: g.$m-xxxl !important; }
	}
}
