@charset "utf-8";
@use "../../global/_" as g;
.m-block {
	margin-top: 60px;
	&._mt-xs_ {
		margin-top: 20px;
	}
    &._mt-s_ {
		margin-top: 30px;
	}
	&._mt-m_ {
		margin-top: 80px;
	}
	&._mbt-l_ {
		
	}
}
@include g.media-s {
	.m-block {
		margin-top: 30px;
		&._mt-s_ {
			
		}
		&._mt-m_ {
			margin-top: 40px;
		}
		&._mt-l_ {
			
		}
	}
}