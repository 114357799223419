@charset "utf-8";
@use "../../global/_" as g;
.c-flex {
	display: flex;
	margin: 45px 0 0 0;

	&__item {
		flex: 0 0 auto;
		max-width: 50%;

		img {
			max-width: 100%;
		}
		& + * {
			& > *:first-child {
				margin-top: 0;
			}
		}
	}

	// 画像左寄せ
	&._left_ {
		flex-direction: row;
	}
	&._left_ &__item {
		margin-right: 40px;
	}

	// 画像右寄せ
	&._right_ {
		flex-direction: row-reverse;
	}
	&._right_ &__item {
		margin-left: 40px;
	}
    
    &._center_ {
        align-items: center;
    }
}
@include g.media-s {
    .c-flex {
        margin: 25px 0 0 0;
        &._left_ &__item {
			margin-right: 15px;
		}
        &._right_ &__item {
            margin-left: 15px;
        }
        &._sp-fd-c_ {
			flex-direction: column;
		}
		&._sp-fd-c_ &__item {
			align-self: center;
			margin: 0 auto;
		}
		&._sp-fd-c_ &__item + * {
			margin-top: 30px;
		}

		//スマホ 1カラム（画像下）
		&._sp-fd-cr_ {
			flex-direction: column-reverse;
		}
		&._sp-fd-cr_ &__item {
			align-self: center;
			margin: 30px auto 0;
		}
    }
}
