@charset "utf-8";
@forward "./foundations/_";
@forward "./elements/_";
@forward "./modules/_";
@forward "./components/_";
@forward "./layouts/_";
@forward "./templates/_";
@forward "./application/_";
@forward "./utility/_";

@import url('//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600&display=swap');
@import url('//fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('//fonts.googleapis.com/icon?family=Material+Icons');