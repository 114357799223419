@charset "utf-8";
@use "../../../global/_" as g;
.c-schoollife-uniform {
    &__item {
        .__img {
            text-align: center;
            border: 1px solid g.$line-gray;
            padding: 20px;
            img {
                max-height: 500px;
            }
        }
        .e-h4 + .__img {
            margin-top: 30px;
        }
    }
}

@include g.media-m-ov {

}
@include g.media-s {
    .c-schoollife-uniform {
        &__item {
            .__img {
                padding: 20px 0;
                img {
                    max-height: 380px;
                }
            }
        }
    }
}
