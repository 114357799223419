@charset "utf-8";
@use "../../global/_" as g;
.e-a-txt-arrow {
	position: relative;
	display: inline-block;
	padding: 0 0 0 1.25em;
	
	.__ico {
		color: g.$baseColor;
		line-height: 1.2;
		
		&::before {
			display: inline-block;
		}
		&:first-child {
			position: absolute;
			top: 5px;
			left: 5px;
		}
	}
	.__txt {
		color: g.$text-black;
	}
	&:hover {
		.__elem {
			color: g.$baseColor;
		}
	}
	
	
	//色オプション 青 -------------------------------
	
}
@include g.media-s {
	.e-a-txt-arrow {
		.__elem {
			.icon-blank {
				font-size: g.$fz-xs;
			}
		}
	}
}
