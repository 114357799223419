@charset "utf-8";
@use "../../global/_" as g;
.m-tab {
	display: flex;
	flex-wrap: wrap;
    justify-content: center;
    margin: 60px auto 0;
    &__link {
        background: #F2F2F2;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		position: relative;
        height: 100%;
		@include g.transition;
		padding: 10px;
		font-size: 1.5rem;
        color: #7B7B7B;
        border: 1px solid #DFDFDF;
    }
    &__item {
        flex-basis: 50%;
        &._active_ {
            .m-tab__link {
                border: 1px solid g.$text-purple;
                background: g.$text-purple;
                color: g.$white;
                 &::after {
                    border: solid transparent;
                    content: '';
                    height: 0;
                    width: 0;
                    pointer-events: none;
                    @include g.centering-elements(true, false);
                    top: 100%;
                    border-color: rgba(0, 153, 255, 0);
                    border-width: 10px;
                    border-top-color: g.$text-purple;
                }
            }
        }
    }
    &__area {
        display: none;
        opacity: 0;
        background: g.$white;
        margin: 60px 0 0 0;
        &._is-active_ {
            display: block;
            animation-name: displayAnime;
            animation-duration: 2s;
            animation-fill-mode: forwards;
        }
    }
}

@include g.media-m-ov {
    .m-tab {
        &__link:hover {
            color: g.$text-black;
            background: #DFDFDF;
        }
    }
}
@include g.media-s {
    .m-tab {
        margin: 30px auto 0;
        &__link {
			font-size: 1.3rem;
            text-align: center;
        }
        &__area {
            margin: 30px 0 0 0;
        }
    }
}

@keyframes displayAnime{
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}