@charset "utf-8";
@use "../../global/_" as g;
.l-bg {
	&._purplelight_ {
		background: g.$bg-purpleLight;
	}
}
@include g.media-m-ov {
	.l-bg {
		padding: 70px 0;
        .l-row__inner {
            padding-top: 0;
        }
	}
}

@include g.media-m {
	.l-bg {
	}
}
@include g.media-s {
	.l-bg {
		padding-top: 40px;
	}
}


