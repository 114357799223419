@charset "utf-8";
@use "../../global/_" as g;
.e-h2 {
	color: g.$baseColor;
	font-size: 4.0rem;
	line-height: 1.4;
	@include g.font-bold;
	._ta-c_ {
		text-align: center;
	}
    & + .__main {
        margin: 60px 0 0;
    }
    
    & + .e-h3 {
        margin-top: 40px;
    }
    .__sub {
        font-size: 3.0rem;
    }
    &._small_ {
        font-size: 3.0rem;
    }
}

@include g.media-s {
	.e-h2 {
		font-size: 2.8rem;
        & + .__main {
            margin: 30px 0 0;
        }
        .__sub {
            font-size: g.$fz-xxl;
        }
        &._small_ {
            font-size: g.$fz-xxl;
        }
	}
}
