@charset "utf-8";
@use "../../global/_" as g;
.e-btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	min-width: 150px;
	height: 45px;
	margin: 10px 5px;
	padding: 0 30px;
	background: linear-gradient(to right, #2b51a7 0%,#0f1d5b 100%);
	color: g.$white;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	@include g.transition;
	border: 1px solid transparent;

	&::before {
		position: absolute;
		transition: .3s;
		content: '';
		width: 0;
		bottom: 0;
		background: g.$white;
		height: 100%;
		left: 0px;
	}
	
	&::after {
		display: inline-block;
		position: absolute;
		top: 50%;
		right: -15px;
		width: 30px;
		height: 1px;
		content: "";
		background: g.$line-blue;
		@include g.transition;
	}

	&:not(._disabled_) {
		cursor: pointer;
	}
	
	&._disabled_ {
		opacity: .5;
	}
	.icon-file-pdf-o:before {
		color: g.$white;
	}
	
	//サイズオプション Lサイズ-------------------------------
	&._w-l_ {
		min-width: 300px;
		min-height: 60px;
		height: auto;
		padding: 10px 30px;
		font-size: g.$fz-m;
	}
	//サイズオプション Mサイズ-------------------------------
	&._w-m_ {
		min-width: 240px;
		font-size: g.$fz-m;
	}
	//サイズオプション Sサイズ-------------------------------
	&._w-s_ {
		min-width: inherit;
		height: 40px;
	}
	//カラーオプション アウトライン-------------------------------
	&._outline_ {
		background: g.$white;
		border: 1px solid g.$baseColor;
		color: g.$baseColor;
		&:not(._disabled_):hover {
			background: g.$baseColor;
			color: g.$white;
		}
	}
}

@include g.media-m-ov {
	.e-btn {
		&:hover {
			border: 1px solid g.$baseColor;
			&::before {
				left: 0px;
				width: 99%;
			}
			&::after {
				width: 20px;
				right: -20px;
			}
			.icon-file-pdf-o:before {
				color: g.$red;
			}
		}
		.__txt {
			z-index: 2;
		}
		&:not(._disabled_):hover {
			background: g.$white;
			border-color: g.$baseColor;
			color: g.$baseColor;
		}
		&._disabled_ {
			&::after {
				display: none;
			}
			&:hover {
				&::before {
					display: none;
				}
			}
		}
	}
}

@include g.media-s {
	.e-btn {
		height: 50px;
		margin: 5px;
		font-size: g.$fz-m;
		
		//サイズオプション Lサイズ-------------------------------
		&._w-l_ {
			width: 90%;
			min-width: 0;
			min-height: 50px;
			margin: 10px auto;
			display: flex;
		}
		//サイズオプション Mサイズ-------------------------------
		&._w-m_ {
			min-width: 70%;
			font-size: g.$fz-m;
		}
		//サイズオプション Sサイズ-------------------------------
		&._w-s_ {
			min-width: 0;
			min-height: 35px;
			padding: 0 20px;
			font-size: g.$fz-xxs;
		}
	}
}