@charset "utf-8";
@use "../../global/_" as g;
@include g.media-m-ov {
	.c-table-slide {
		&__coution {
			display: none;
		}
	}
}
@include g.media-s {
	.c-table-slide {
		&__coution {
			font-size: g.$fz-xxs;
		}
		&__body {
			margin: g.$m-xxxs 0;
			overflow: auto;
			overflow-scrolling: touch;
			
			table {
				white-space: nowrap;
			}
		}
	}
}
