@charset "utf-8";
@use "../../../global/_" as g;
.u-mb-xxs {
	margin-bottom: g.$m-xxs !important;

	@include g.media-s {
		&._sm-mb-0_    { margin-bottom: 0 !important; }
		&._sm-mb-xxxs_ { margin-bottom: g.$m-xxxs !important; }
		&._sm-mb-xxs_  { margin-bottom: g.$m-xxs !important; }
		&._sm-mb-xs_   { margin-bottom: g.$m-xs !important; }
		&._sm-mb-s_    { margin-bottom: g.$m-s !important; }
		&._sm-mb-m_    { margin-bottom: g.$m-m !important; }
		&._sm-mb-l_    { margin-bottom: g.$m-l !important; }
		&._sm-mb-xl_   { margin-bottom: g.$m-xl !important; }
		&._sm-mb-xxl_  { margin-bottom: g.$m-xxl !important; }
		&._sm-mb-xxxl_ { margin-bottom: g.$m-xxxl !important; }
	}
}
