@charset "utf-8";
@use "../vars/_" as v;

// ============================================================
//  breakpoint 
// ============================================================
@mixin media-m-ov {
	@media screen and (min-width: 767.5px) {
		@content;
	}
}

@mixin media-m {
	@media screen and (min-width: 767.5px) and (max-width: 1024.499px) {
		@content;
	}
}

@mixin media-s {
	@media screen and (max-width: 767.499px) {
		@content;
	}
}


// ============================================================
//  font 
// ============================================================
@mixin font-normal {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 400;
}

@mixin font-medium {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 500;
}

@mixin font-bold {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 600;
}

@mixin font-poppins {
	font-family: "Poppins", sans-serif;
    font-weight: 700;
}
@mixin font-poppins-medium {
	font-family: "Poppins", sans-serif;
    font-weight: 500;
}
@mixin font-poppins-regular {
	font-family: "Poppins", sans-serif;
    font-weight: 400;
}


// ============================================================
//  grid 
// ============================================================
@mixin column($col){
	width: calc(#{v.$max-width} / #{v.$maxcol} * #{$col} - #{v.$margin});

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		width: calc(#{v.$max-width} / #{v.$maxcol} * #{$col} - #{v.$margin} - 1px);
  }
  margin-left: calc(#{v.$margin} / 2);
  margin-right: calc(#{v.$margin} / 2);
  margin-bottom: v.$margin;
}
@mixin sp-column($col){
	width: calc(#{v.$max-width} / #{v.$maxcol} * #{$col} - #{v.$margin-sp});
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		width: calc(#{v.$max-width} / #{v.$maxcol} * #{$col} - #{v.$margin-sp} - 1px);
  }
  margin-left: calc(#{v.$margin-sp} / 2);
  margin-right: calc(#{v.$margin-sp} / 2);
  margin-bottom: v.$margin-sp;
}


// ============================================================
//  other 
// ============================================================
@mixin hidden {
	font-size: 1px;
	position: absolute;
	top: -999999px;
	left:-999999px;
	clip: rect(1px, 1px, 1px, 1px);
	width: 1px;
	height: 1px;
	display: block;
	opacity: 0;
}

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition($duration: .4s) {
	transition: $duration all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin bg-gradation-side($left: true) {
    background: rgb(154,94,190);
    @if $left {
		background: linear-gradient(90deg, rgba(154,94,190,1) 0%, rgba(206,183,255,1) 100%);
	} @else {
		background: linear-gradient(270deg, rgba(154,94,190,1) 0%, rgba(206,183,255,1) 100%);
	}
    
}

@mixin bg-gradation-vertical($left: true) {
    background: rgb(154,94,190);
    @if $left {
		background: linear-gradient(180deg, rgba(154,94,190,1) 0%, rgba(206,183,255,1) 100%);
	} @else {
		background: linear-gradient(0deg, rgba(154,94,190,1) 0%, rgba(206,183,255,1) 100%);
	}
    
}


@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin iehack() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}