@charset "utf-8";
@use "../../global/_" as g;
.m-pagination {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 60px 0 0;
	line-height: 1.4;

	&__item {
		margin: 10px 5px 0;
	}
	&__link {
		display: block;
		padding: 10px 15px;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
		background: #F2F2F2;
		border: 1px solid #DFDFDF;
		text-decoration: none;
        font-size: 1.5rem;

		._current_ & {
			background: g.$text-purple;
			border: 1px solid g.$text-purple;
			color: g.$white;
			opacity: 1;
		}
	}
}
@include g.media-m-ov {
	.m-pagination {
        &__link:hover {
            color: g.$text-black;
        }
        &__item:not(._disabled_) {
            .m-pagination__link {
                @include g.transition;
                &:hover {
                    background: g.$baseColor;
                    border: 1px solid g.$baseColor;
                    color: g.$white;
                    opacity: 1;
                }
			}
        }
	}	
}
@include g.media-s {
	.m-pagination {
        margin: 30px 0 0;
		&__item {
			margin: 0 3px 10px;
		}
		&__link {
			padding: 8px 12px;
		}
	}
}
