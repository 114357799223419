@charset "utf-8";
@use "../../global/_" as g;
.e-iframe {
	max-width: 100%;
	text-align: center;
	iframe {
		max-width: 100%;
	}
}
@include g.media-s {
	.e-iframe {
		position: relative;
		height: 0;
		padding-bottom: 56.25%;
		overflow: hidden;

		.__elem,
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
