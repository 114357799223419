@charset "utf-8";
@use "../../global/_" as g;
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?l0zuoj');
  src:  url('/common/icon/fonts/icomoon.eot?l0zuoj#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?l0zuoj') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?l0zuoj') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?l0zuoj') format('woff'),
    url('/common/icon/fonts/icomoon.svg?l0zuoj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-external-link:before {
  content: "\f08e";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-file-word-o:before {
  content: "\f1c2";
}
.icon-file-excel-o:before {
  content: "\f1c3";
}
