@charset "utf-8";
@use "../../../global/_" as g;
.u-fz-16 {
	font-size: g.$fz-m !important;

	@include g.media-s {
		&._sm-fz-10_ { font-size: g.$fz-xxxs !important; }
		&._sm-fz-12_ { font-size: g.$fz-xxs !important; }
		&._sm-fz-14_ { font-size: g.$fz-s !important; }
		&._sm-fz-18_ { font-size: g.$fz-l !important; }
		&._sm-fz-20_ { font-size: g.$fz-xl !important; }
		&._sm-fz-22_ { font-size: g.$fz-xxl !important; }
		&._sm-fz-24_ { font-size: g.$fz-xxxl !important; }
	}
}
