@charset "utf-8";
@use "../../../global/_" as g;
.e-form-select {
	display: inline-block;
	border: 1px solid #E5E5E5;   
    margin: 5px 10px 0 0;
    border-radius: 4px;
    position: relative;
    &::before {
        content: "\e313";
        font-family: 'Material Icons';
        color: g.$text-purple;
        @include g.centering-elements(false, true);
        right: 5px;
        pointer-events: none;
    }
    &__item {
        width: 100%;
        padding: 8px 30px 8px 15px;
        -webkit-appearance: none;
        appearance: none; 
        cursor: pointer;
        background: #F6F7F8;
        min-width: 210px;
    }
}

@include g.media-m-ov {
	.e-form-select {

	}
}

@include g.media-s {
	.e-form-select {
		width: 100%;
	}
}

