@charset "utf-8";
@use "../../global/_" as g;
.m-pager {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0 5px;
	grid-template-areas: "prev list next";
	margin: 80px 0 0;
	line-height: 1.4;
    border-top: 1px solid g.$line-gray;
    padding: 40px 0 0;
    

	&__item {
		display: flex;
		
		&._prev_ {
			grid-area: prev;
			justify-content: flex-start;
            .__ico {
                margin: 0 20px 0 0;
            }
		}
		&._next_ {
			grid-area: next;
			justify-content: flex-end;
            .__ico {
                margin: 0 0 0 20px;
            }
		}
		&._list_ {
			grid-area: list;
			justify-content: center;
		}
	}
	&__link {
		display: block;
		padding: 10px 15px;
		background: #F2F2F2;
		border: 1px solid g.$line-gray;
		text-decoration: none;

		&._current_ {
			background: g.$baseColor;
			border: 1px solid g.$baseColor;
			color: g.$white;
			opacity: 1;
		}
        
	}
}
@include g.media-m-ov {
	.m-pager {
		&__link {
			@include g.transition;
			&:hover {
				border: 1px solid g.$baseColor;
				background: g.$baseColor;
				color: g.$white;
				opacity: 1;
			}
		}
	}	
}
@include g.media-s {
	.m-pager {
        margin: 50px 0 0;
        padding: 30px 0 0;
		&__item {
			margin: 0 3px 10px;
		}
		&__link {
			padding: 8px 12px;
		}
	}
}
