@charset "utf-8";
@use "../../global/_" as g;
.m-table {
	width: 100%;
	margin: 45px 0 0;
	th,
	td {
		padding: 15px 20px;
		border: 1px solid g.$line-gray;
		text-align: left;
		vertical-align: middle;
	}
	th {
		@include g.font-medium;
        text-align: center;
	}
	._al-l_ {
		text-align: left;
	}
	._al-r_ {
		text-align: right;
	}
	._al-c_ {
		text-align: center;
	}
    ._va_t_ {
		vertical-align: top;
	}
	._nowrap_ {
		white-space: nowrap;
	}
	._purplelight_ {
		background: rgba(g.$baseColor,0.63);
		color: g.$white;
	}
	._gray_ {
		background: #f2f2f2;
	}
	
	&._scroll_ {
		overflow-x: auto;
		.m-table__item {
			width: 100%;
		}
	}
    &._history_ {
        th,td {
            border: none;
        }
        th {
            color: g.$text-purple;
            width: 14%;
            white-space: nowrap;
            text-align: left;
            vertical-align: top;
        }
        ._gray_ {
            th,td {
                background: #f2f2f2;
            }
        }
        .__month {
            margin: 0 0 0 20px;
        }
        ._same_ {
            th,td {
                padding: 0 20px 15px;
            }
            .__month {
                margin-left: calc(4em + 22px);
            }
        }
    }
    &._no-br_ {
        th,td {
            border: none;
            padding: 15px 20px 0 0; 
        }
    }
    &._ta-c_ {
        th,td {
            text-align: center;
        }
    }
    &._inline_ {
        display: inline-block;
    }
}


@include g.media-s {
	.m-table {
        margin: 25px 0 0 0;
		th,
		td {
			padding: 15px;
		}
		._sp-al-l_ {
            text-align: left;
	    }
        ._sp-al-c_ {
            text-align: center;
	    }
        ._sp-al-r_ {
            text-align: right;
	    }
		//SP 1カラム -------------------------------
		&._sp-col1_ {
			display: block;
			
			thead,
			tbody,
			tr,
			th,
			td {
				display: block;
				border-bottom: none;
			}
			th {
				padding: 10px 15px;
                text-align: left;
			}
			tr:last-child {
				td {
					border-bottom: 1px solid g.$line-gray;
				}
			}
		}
		
		//横長 -------------------------------
		&._wide_ {
			border: none;
			display: block;

			th,
			td {
				display: block;
				padding: 5px 8px;
				border-bottom-width: 0;
				border-right-width: 0;
				text-align: center;
			}
			th{  
				width: auto;
				text-align: left;
			}
		}
		&._wide_ &__head {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		&._wide_ &__body {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		&._wide_ &__head th:last-child,
		&._wide_ &__body td:last-child {
			border-bottom-width: 1px;
		}
        
		&._sp-ta-l_ {
            th,td {
                text-align: left;
            }
        }
		//リスト風 -------------------------------
		&._list_ {
			border: none;

			tr {
				display:block;
				margin: 0 0 20px;
			}
			th {
				display: block;
			}
			td {
				display: list-item;
				margin: 0 0 0 20px;
				padding: 5px 0 0;
				border: none;
				background: none;
			}
		}
        
        &._history_ {
            th {
                width: 100%;
                text-align: left;
                padding-bottom: 0;
            }
            td {
                padding-top: 5px;
            }
            .__month {
                margin: 0 0 0 15px;
            }
            ._same_ {
                th {
                    padding: 15px 15px 0;
                }
                td {
                    padding: 5px 15px 15px 15px;
                }
                .__month {
                    margin: 0 0 0 15px;
                }
            }
        }
        &._examination_events_ {
            tr:first-child th,
            tr:first-child td {
                border-top: none;
            }
        }
	}
}
