@charset "utf-8";
@use "../../../global/_" as g;
.u-pr-xxxs {
	padding-right: g.$m-xxxs !important;
	
	@include g.media-s {
		&._sm-pr-0_    { padding-right: 0 !important; }
		&._sm-pr-xxxs_ { padding-right: g.$m-xxxs !important; }
		&._sm-pr-xxs_  { padding-right: g.$m-xxs !important; }
		&._sm-pr-xs_   { padding-right: g.$m-xs !important; }
		&._sm-pr-s_    { padding-right: g.$m-s !important; }
		&._sm-pr-m_    { padding-right: g.$m-m !important; }
		&._sm-pr-l_    { padding-right: g.$m-l !important; }
		&._sm-pr-xl_   { padding-right: g.$m-xl !important; }
		&._sm-pr-xxl_  { padding-right: g.$m-xxl !important; }
		&._sm-pr-xxxl_ { padding-right: g.$m-xxxl !important; }
	}
}
