@charset "utf-8";
@use "../../../global/_" as g;
.c-index-ttl01 {
	font-size: g.$fz-s;
	@include g.font-medium;
	position: relative;
	margin: 0 0 35px;
    &::before {
        color: g.$baseColor;
        font-size: 4.2rem;
        @include g.font-poppins;
        display: block;
        content: attr(data-text);
        margin: 10px 0 0;
    }
}

@include g.media-m {
	.c-index-ttl01 {
		padding: 0 0 0 30px;
	}
}
@include g.media-s {
	.c-index-ttl01 {
		margin: 0 0 25px;
        &::before {
            font-size: 3.2rem;
        }
	}
}
