@charset "utf-8";
@use "../../global/_" as g;
.m-box {
	padding: 25px;
	background: g.$white;
    margin: 45px 0 0 0;

	&._gray_ {
		background: #F3F3F3;
	}
	&._line-gray_ {
		border: 1px solid g.$line-gray;
	}
    &._line-gray-fat_ {
		border: 5px solid #DFDFDF;
	}
	
	&._pink_ {
		background: #FFF1F1;
		border: 1px solid #FFCFCF;
	}
	&._lightblue_ {
		background: g.$bg-purpleLight02;
	}
	
    & > *:first-child {
        margin: 0;
    }
    &._wide_ {
        padding: 40px;
    }
    & + p {
        margin-top: 25px;
    }
    & + & {
        margin-top: 25px;
    }
}
@include g.media-s {
	.m-box {
        margin: 25px 0 0 0;
		padding: 15px;
        &._wide_ {
            padding: 20px;
        }
    }
}