@charset "utf-8";
@use "../../global/_" as g;
.m-modal {
	&.iziModal {
		font-size: g.$fz-m;
		line-height: 1.6;
	}
}
@include g.media-s {
	.m-modal {
		&.iziModal {
			margin-left: 15px;
			margin-right: 15px;
		}
	}
}