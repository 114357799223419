@charset "utf-8";
@use "../../global/_" as g;
.c-contact-form {
	padding-top: 80px;
	
	&__inner {
		margin-top: -80px;
		padding-top: 80px;
	}
}
@include g.media-s {
	.c-contact-form {
		padding-top: 50px;

		&__inner {
			margin-top: -50px;
			padding-top: 50px;
		}
	}
}
