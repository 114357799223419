@charset "utf-8";
@use "../../global/_" as g;
.c-blog {
    &__head{
        margin: 40px 0 0 0;
    }
}

@include g.media-m-ov {
	.c-blog {

	}
}

@include g.media-s {
	.c-blog {

	}
}
