@charset "utf-8";
@use "../../global/_" as g;
.c-grid {
	display: flex;
	flex-wrap: wrap;
    margin: 45px 0 0;
	&:not([class*="_col"]) {
        margin-left: -calc(#{g.$margin} / 2);
		margin-right: -calc(#{g.$margin} / 2);
	}
	
	&._characteristic_ {
		justify-content: space-between;
	}
	
	.__col {
		width: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		
		@for $i from 1 through g.$maxcol {
			&._col-#{$i}_ {
				@include g.column($i);
			}
		}
        
		img {
			max-width: 100%;
		}
		&._c-txt_ {
			width: calc(100% - 380px);
		}
		&._c-img_ {
			max-width: 330px;
		}
	}
	&._stretch_ {
    .__col {
      display: flex;
    }
  }	
}
@include g.media-m-ov {
	.c-grid {
		.__col {
			@for $i from 1 through g.$maxcol {
				&._col-lg-#{$i}_ {
					@include g.column($i);
				}
			}
		}
		
		//2カラム -----------------------------------
		&._col2_ {
			.__col {
				flex-basis: calc((100% - #{g.$margin}) / 2);
				&:not(:nth-child(2n+1)) {
					margin-left: g.$margin;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: g.$margin;
				}
                &:first-child,
                &:nth-child(2) {
                    .e-h3:first-child {
                        margin: 0;
                    }
                }
			}
            &._wide_ {
                .__col {
                    flex-basis: calc((100% - 40px) / 2);
                    &:not(:nth-child(2n+1)) {
                        margin-left: 40px;
                    }
                }
            }
		}

		//3カラム -----------------------------------
		&._col3_ {
			.__col {
				flex-basis: calc((100% - #{g.$margin} * 2) / 3);
				&:not(:nth-child(3n+1)) {
					margin-left: g.$margin;
				}
				&:not(:nth-child(-n+3)) {
					margin-top: g.$margin;
				}
                &:first-child,
                &:nth-child(2),
                &:nth-child(3) {
                    .e-h5:first-child {
                        margin: 0;
                    }
                }
			}
		}

		//4カラム -----------------------------------
		&._col4_ {
			.__col {
				flex-basis: calc((100% - #{g.$margin} * 3) / 4);
				&:not(:nth-child(4n+1)) {
					margin-left: g.$margin;
				}
				&:not(:nth-child(-n+4)) {
					margin-top: g.$margin;
				}
				//news-list -----------------------------------
				&.c-news-list__item {
					margin: 0 5px 20px;
					flex-basis: calc((100% - 40px) / 4);
					background: g.$white;
					&:not(:nth-child(4n+1)) {
						margin-left: 5px;
					}
					&:not(:nth-child(-n+4)) {
						margin-top: 0;
					}
				}
			}
			&.c-index-newsBody {
				margin: 0 -10px;
			}
		}

		//5カラム -----------------------------------
		&._col5_ {
			.__col {
				flex-basis: calc((100% - #{g.$margin} * 4) / 5);
				&:not(:nth-child(5n+1)) {
					margin-left: g.$margin;
				}
				&:not(:nth-child(-n+5)) {
					margin-top: g.$margin;
				}
			}
		}
	}
}
@include g.media-m {
	.c-grid {
		.__col {
			@for $i from 1 through g.$maxcol {
				&._col-md-#{$i}_ {
					@include g.column($i);
				}
			}
		}
	}
}
@include g.media-s {
	.c-grid {
        margin: 25px 0 0 0;
		.__col {
			@for $i from 1 through g.$maxcol {
				&._col-#{$i}_ {
					@include g.sp-column($i);
				}
				&._col-sm-#{$i}_ {
					@include g.sp-column($i);
				}
				&._c-img_ {
					max-width: 100%;
				}
			}
			&._ta-c_ {
				text-align: center;
			}
		}
		
		//2～5カラム（SP表示は2カラムを基本とする） -----------------------------------
		&._col2_,
		&._col3_,
		&._col4_,
		&._col5_ {
			.__col {
				flex-basis: calc((100% - #{g.$margin-sp}) / 2);
				&:not(:nth-child(2n+1)) {
					margin-left: g.$margin-sp;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: g.$margin-sp;
				}
				//news-list -----------------------------------
				&.c-news-list__item {
					margin: 0 5px 10px;
					flex-basis: calc((100% - #{g.$margin-sp} * 2) / 2);
					&:not(:nth-child(2n+1)) {
						margin-left: 5px;
					}
					&:not(:nth-child(-n+2)) {
						margin-top: 0;
					}
				}
                .e-h5:first-child,
                .e-h5:nth-child(2) {
                    margin: 0 0 0 0;
                }
			}
			&._wide_ {
				.__col {
					&:not(:nth-child(-n+2)) {
						margin-top: g.$margin-sp * 2;
					}
				}
			}
		}
		
		
		//スマホ強制1カラム -----------------------------------
		&._sp-col1_ {
			.__col {
				flex: 0 0 100%;
				width: 100%;

				&:not(:nth-child(2n+1)) {
					margin-left: 0;
				}
				&:not(:nth-child(-n+1)) {
					margin-top: 30px;
				}
                .e-h3:first-child {
                    margin: 20px 0 0 0;
                }
			}
            &._list_ {
                .__col {
                    margin-top: 0;
                    .e-list-circle {
                        margin-top: 0;
                    }
                }
            }
		}
		&._sp-col-r_ {
			flex-direction: column-reverse;
			.__col {
				&:not(:nth-child(-n+1)) {
					margin-top: 0;
					margin-bottom: 30px;
				}
			}
		}
	}
}
