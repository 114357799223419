@charset "utf-8";
@use "../../global/_" as g;
.e-lead {
	margin: 40px 0 0 0;
}

@include g.media-s {
	.e-lead {
		margin: 25px 0 0 0;
	}
}
