@charset "utf-8";
@use "../../global/_" as g;
.c-footer {
	background: g.$baseColorDarken;
	line-height: 1.6;
	padding: 50px 0 0 0;
	&__inner {
		max-width: 1240px;
        padding: 0 20px;
		margin: 0 auto;
	}
	&Logo {
		display: flex;
        justify-content: space-between;
        color: #BBBBBB;
        font-size: g.$fz-xs;
        &__body {
            display: flex;
            align-items: center;
        }
        &__link {
            display: block;
            margin: 0 30px 0 0;
            .__elem {
                width: 100%;
                max-width: 230px;
            }
        }
        a[href^="tel:"] {
            color: #BBBBBB;
        }
        &__sns {
            background: g.$white;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
	}
	&Fnav {
		border-top: 1px solid #44364B;
		width: 100%;
		margin: 50px 0 0;
        padding: 60px 0;
		color: g.$white;
		line-height: 1.5;
		display: flex;
		&__item {
			margin: 0 0 30px;
		}
		&__link {
			color: g.$white;
			font-size: 1.5rem;
			position: relative;
			width: 100%;
			text-decoration: none;
			&._link_{
				.icon-filter_none {
					color: g.$white;
				}
			}
		}
        &__dropdown {
            margin: 5px 0 0 0;
        }
        &__sub-item {
            position: relative;
            padding: 0 0 0 15px;
            &::before {
                @include g.centering-elements(false, true);
                left: 0;
                content: "";
                width: 5px;
                height: 1px;
                border-bottom: 1px solid #7C598E;
            }
        }
		&__sub-link {
			color: #BBBBBB;
			font-size: g.$fz-xs;
			text-decoration: none;
		}
		&__btn {
			border: 1px solid #7A7A7A;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 30px 0 0;
			width: 210px;
            max-width: 90%;
			color: g.$white;
			text-decoration: none;
			height: 50px;
			@include g.transition;
			font-size: g.$fz-s;
            padding: 0 15px 0 10px;
			.__ico {
				font-size: g.$fz-l;
                right: 5px!important;
			}
		}
	}

	&Copyright {
		background: #2D2333;
		color: #756A7B;
		font-size: g.$fz-xxs;
        @include g.font-poppins-regular;
        &__inner {
            max-width: 1240px;
            padding: 30px 20px;
            margin: 0 auto;
            text-align: right;
        }
	}
}
@include g.media-m-ov {
	.c-footer {
        &Logo {
            &__sns {
                @include g.transition;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
		&Fnav{
			&__wrap {
				width: 25%;
                &:last-child {
                    .c-footerFnav__item {
                        margin: 0 0 15px;
                    }
                }
			}
			&__link {
				.__ico {
					display: none;
				}
			}
			&__link {
				&._link_{
					@include g.transition;
					&:hover {
						text-decoration: underline;
						color: g.$white;
					}
				}
			}
			&__sub-link {
				@include g.transition;
				&:hover {
					opacity: 0.7;
					text-decoration: underline;
					color: g.$white;
				}
			}
			&__btn {
				&:hover {
					background: #A0A8CF;
					color: g.$baseColor;
					.__ico {
						color: g.$baseColor;
					}
				}
			}
		}
		&Copyright {
			&__link {
				display: none;
			}
		}
	}
}

@include g.media-m {
	.c-footer {
		&Fnav {
			&__link {
				padding: 0 20px 0 25px;
				font-size: g.$fz-s;
				&::before {
					width: 15px;
				}
			}
            &__btn {
                font-size: g.$fz-xxs;
                padding: 0 15px 0 10px;
                .__ico {
                    font-size: g.$fz-l;
                    right: 5px!important;
                }
            }
		}
	}
}

@include g.media-s {
	.c-footer {
			padding: 0 0 50px;
		&__inner {
			padding: 15px 15px 0;
		}
		&Logo {
            font-size: g.$fz-xxs;
            &__body {
                flex-direction: column;
                align-items: flex-start;
                margin: 20px 0 0;
            }
            &__link {
                margin: 0 0 10px;
                .__elem {
                    max-width: 200px;
                }
            }
            &__sns {
                width: 35px;
                height: 35px;
                .__ico {
                    max-width: 15px;
                }
            }
		}
		&Fnav {
			border-top: 1px solid #3A3A3A;
			margin: 35px 0 0;
			display: block;
            padding: 0;
			&__item {
				border-bottom: 1px solid #3A3A3A;
				margin: 0;
				width: 100%;	
			}
			&__link {
				display: block;
				padding: 15px;
				text-decoration: none;
				position: relative;
				.__ico {
					@include g.centering-elements(false, true);
					right: 15px;
					@include g.transition;
				}
				&._active_ {
					.__ico:before {
						content: "\f106";
					}
				}
			}
			&__dropdown {
				display: none;
				padding: 0 0 15px;
                margin: 0;
			}
            &__sub-item {
                &::before {
                    left: 20px;
                }
            }
			&__sub-link {
				padding: 5px 15px 5px 20px;
				display: block;
				width: 100%;
				height: 100%;
			}
            &__wrap {
                &:last-child {
                    .c-footerFnav__item {
                        border: none;
                        padding: 15px 0 0;
                    }
                    .c-footerFnav__link {
                        padding: 0 0 0 25px;
                        .__ico {
                            right: inherit;
                            left: 5px;
                            color: g.$baseColor;
                        }
                    }
                }
			}
			&__type {
				padding: 30px 0;
			}
		}
		&Copyright {
			display: block;
			text-align: center;
            font-size: 1.0rem;
			&__add {
				margin: 0 0 20px;
			}
			&__link {
				display: flex;
				margin: 0 auto 20px;
				width: 80%;
				justify-content: center;
			}
			&__btn {
				border: 1px solid #3A3A3A;
				display: flex;
				align-items: center;
				justify-content: center;
				color: g.$white;
				background: #242424;
				padding: 10px 0;
				width: 50%;
				text-decoration: none;
				font-size: g.$fz-s;
				.__ico {
					font-size: g.$fz-l;
					margin: 0 5px 0 0;
					color: #ABB2C4;
				}
				&:first-child {
					border-right: 0;
				}
			}
			&__copy {
				font-size: g.$fz-xxxs;
				margin: 0 15px;
			}
		}
	}
}
