@charset "utf-8";
@use "../../global/_" as g;
.c-gnav {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	&Head {
		height: 65px;
		display: flex;
		align-items: center;
        @include g.bg-gradation-side(true);
		padding: 0 20px;
		&__link {
			.__elem {
				width: 200px;
			}
		}
	}
	&Menu {
		position: absolute;
		width: 65px;
		height: 65px;
		z-index: 10;
		margin-left: auto;
		cursor: pointer;
		transition: all 0.5s ease;
		@include g.bg-gradation-vertical(true);
		right: 0;
		display: none;

		&__item {
			display: inline-block;
			@include g.centering-elements(true, false);
			width: 50%;
			height: 2px;
			background: g.$white;
			@include g.radius(2);
			@include g.transition;

			&:nth-of-type(1) { top: 15px; }
			&:nth-of-type(2) { top: 27px; }
			&:nth-of-type(3) { top: 38px; }
		}
		&__txt {
			@include g.centering-elements(true, false);
			bottom: 15px;
			color: g.$white;
			font-size: g.$fz-xxs;
			width: 100%;
			text-align: center;
			.__txt {
				&._close_ {
					display: none;
				}
			}
		}
		&._active_ {
			.c-gnavMenu__item {
                background: g.$text-black;
				&:nth-of-type(1) { transform: translateY(4px) translateX(-15px) rotate(-335deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-19px) translateX(-16px) rotate(335deg); } 
			}
			.c-gnavMenu__txt {
				.__txt {
                    color: g.$text-black;
					&._open_ {
						display: none;
					}
					&._close_ {
						display: block;
					}
				}
			}
		}
		.index & {
			position: fixed;
			width: 80px;
			height: 80px;
			z-index: 10px;
			@include g.bg-gradation-vertical(true);
			display: block;
		}
		.index &._active_ {
			z-index: 10;
			position: absolute;
			width: 60px;
			height: 65px;
			background: g.$white;
			display: none;
		}
	}
	&__wrap {	
		top: 0;
		z-index: 1;
		opacity: 1;
		pointer-events: auto;
		right: 0;
		transition: right 0.5s ease;
		background: #F6F7F8;
        box-shadow: -5px 0 10px rgba(0,0,0,0.05);
		width: 280px;
		height: 100%;
		position: absolute;
		.index & {
			right: -280px;
			pointer-events: none;
		}
		.index._gnav-show_ & {
			z-index: 1;
			opacity: 1;
			pointer-events: auto;
			right: 0;
		}
	}
	&__inner {
		min-height: 100%;
	}
	&Menu1 {
		display: flex;
		&__item {
			width: 50%;
			height: 45px;
			&:first-child {
				border-right: 1px solid g.$line-gray;
			}
		}
		&__link {
			background: g.$white;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			font-size: g.$fz-s;
			@include g.transition;
			.__ico {
				margin: 0 5px 0 0;
				font-size: g.$fz-xxl;
                color: g.$baseColor;
			}
		}
	}
	&Menu3 {
		&__item {
			border-bottom: 1px solid g.$line-gray;
			font-size: 1.5rem;
            background: #F6F7F8;
			&:first-child {
				border-top: 1px solid g.$line-gray;
			}
			.about &._about_,
			.education &._education_,
			.career &._career_,
			.school_life &._school_life_,
			.club &._club_,
            .entrance &._entrance_ {
				@include g.bg-gradation-side(true);
                .c-gnavMenu3__link {
                    color: g.$white;
                    &::before {
                        color: g.$white;
                    }
                }
			}
		}
		&__link {
			color: g.$text-black;
			text-decoration: none;
			position: relative;
			width: 100%;
			height: 100%;
			padding: 12px 15px 12px 35px;
			display: block;
			@include g.font-normal;
            &::before {
                @include g.transition;
                @include g.centering-elements(false, true);
                left: 10px;
                font-family: 'Material Icons';
                content: "\e315";
                color: g.$baseColor;
                font-size: g.$fz-xl;
            }
		}
        &__inner {
            border-top: 1px solid #8C5DA8;
        }
		&__subitem {
			display: block;
			padding: 15px 15px 15px 30px;
			color: g.$white;
			text-decoration: none;
			font-size: 1.5rem;
			@include g.transition;
            border-bottom: 1px solid #8C5DA8;
			.__ico {
				margin: 0 5px 0 0;
				color: g.$white;
				display: inline-block;
			}
		}
	}
	&Menu4 {
		padding: 15px 15px 20px 25px;
        &__item {
            margin: 5px 0 0 0;
        }
		&__link {
			text-decoration: none;
			font-size: g.$fz-s;;
			@include g.transition;
			.__ico {
				margin: 0 5px 0 0;
				color: g.$baseColor;
				display: inline-block;
			}
			.icon-filter_none {
				color: g.$white;
				margin: 0 0 0 5px;
			}
		}
	}

	&Menu5 {
		margin: 0 10px;
		&__item {
            display: flex;
            justify-content: center;
            align-items: center;
			margin: 10px 5px 0;
            height: 50px;
			@include g.transition;
            &._sns_ {
                border: 1px solid g.$line-gray;
                padding: 0;
                text-decoration: none;
                background: g.$white;
                .c-gnavMenu5__item__icon {
                    background: g.$black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    flex: 0 0 50px;
                }
                .c-gnavMenu5__item__txt {
                    flex: 1 1 auto;
                    text-align: center;
                }
            }
		}
	}
}
@include g.media-m-ov {
	.c-gnav {
		min-height: 100%;
		.index._gnav-show_ & {
			min-height: 100%;
			width: 280px;
			overflow: inherit;
			height: inherit;
		}
		&._nav-fixed_ {
			position: fixed;
		}
		
		&Menu1 {
			&__link {
				&:hover {
					background: g.$baseColorLight;
					color: g.$white;
                    .__ico {
                        color: g.$white;
                    }
				}
			}
		}
		&Menu3 {
			&__item {
				@include g.transition;
				cursor: pointer;
				&:nth-child(2) {
					border-top: 1px solid g.$line-gray;
				}
				&:hover {
					@include g.bg-gradation-side(true);
                    color: g.$white;
					.c-gnavMenu3__link {
						@include g.bg-gradation-side(true);
                        color: g.$white;
                        &::before {
                            color: g.$white;
                        }
					}
				}
				&._active_ {
                    .c-gnavMenu3__link._down_ {
                        &::before {
                            content: "\e314";
                        }
                    }
					.c-gnavMenu3__dropdown {
						padding: 270px 0 0 0;
						transition: opacity 0.6s ease;
						width: 280px;
						height: 100%;
						overflow: hidden;
						position: absolute;
						top: 0;
						right: 280px;
						cursor: default;
						opacity: 1.0;
						filter: alpha(opacity=0);
						background-color: rgba(124,68,158,0.9);
					}
					.c-gnavMenu3__subitem {
						pointer-events: inherit;
					}
				}
			}
			&__link {
				@include g.transition;
			}
			&__dropdown {
				opacity: 0;
				width: 0;
				height: 0;
			}
			&__subitem {
				@include g.transition;
				pointer-events: none;
				&:hover {
                    color: g.$white;
					background: rgba(101,61,126,0.8);
				}
			}
		}
		&Menu4 {
			&__link {
				&:hover {
					color: g.$baseColor;
				}
			}
		}
		&Menu5 {
			&__item {
				&:hover {
					opacity: 0.7;
                    color: g.$text-black;
				}
			}
		}
	}
}

@include g.media-m {
	.c-gnav {
		position: fixed;
		height: 80px;
		width: 80px;
		overflow: hidden;
		min-height: inherit;
		._gnav-show_ & {
			min-height: 100%;
			width: 280px;
			overflow: inherit;
			height: inherit;
		}
		._gnav-show_ & {
			min-height: 100%;
		}
        &Head {
            &__link {
                .__elem {
                    width: 170px;
                }
            }
        }
		&Menu {
			.index &._active_ {
				display: block;
			}
		}
		&__inner {
			overflow-y: auto;
			overflow-x: hidden;
			height: 100vh;
			padding-bottom: 50px;
			&::-webkit-scrollbar{
			  width: 10px;
			}
			&::-webkit-scrollbar-track{
			  background: g.$bg-purpleLight;
			  border: none;
			}
			&::-webkit-scrollbar-thumb{
			  background: g.$baseColorLight;
			  border-radius: 10px;
			  box-shadow: none;
			}
		}
		&Menu {
			display: block;
			position: fixed;
			width: 80px;
			height: 80px;
			z-index: 0;
			@include g.bg-gradation-vertical(true);
			&._active_ {
				z-index: 10;
				position: absolute;
				width: 65px;
				height: 65px;
				background: g.$white;
			}
		}
		&__wrap {
			right: -280px;
			z-index: -1;
			opacity: 0;
			pointer-events: none;
			._gnav-show_ & {
				z-index: 1;
				opacity: 1;
				pointer-events: auto;
				right: 0;
			}
		}
	}
}

@include g.media-s {
	.c-gnav {
		top: 0;
		right: 0;
		width: 0;
		position: fixed;
		._gnav-show_ & {
			width: 100%;
			height: 100%;
            @include g.bg-gradation-side(true);
		}
		&Menu {
			position: fixed;
			width: 60px;
			height: 60px;
			z-index: 10;
			top: 0;
			display: block;
			.index & {
				width: 60px;
				height: 60px;
			}
			&__item {
				&:nth-of-type(1) { top: 12px; }
				&:nth-of-type(2) { top: 22px; }
				&:nth-of-type(3) { top: 32px; }
			}
			&__txt {
				bottom: 7px;
			}
			&._active_ {
				width: 60px;
				height: 60px;
				right: 0;
				position: fixed;
                background: g.$white;
				.c-gnavMenu__item {
					&:nth-of-type(1) { transform: translateY(10px) translateX(-14px) rotate(-335deg); }
					&:nth-of-type(2) { opacity: 0; }
					&:nth-of-type(3) { transform: translateY(-10px) translateX(-15px) rotate(335deg); } 
				}
			}
			.index &._active_ {
				width: 60px;
				height: 60px;
				position: fixed;
				display: block;
			}
		}
		&Head {
			display: none;
		}
		&__wrap {
			top: 60px;
			width: 100%;
			transition: top 0.5s ease;
		}
		&__inner {
			overflow-y: auto;
			height: 100vh;
			&::-webkit-scrollbar{
			  width: 10px;
			}
			&::-webkit-scrollbar-track{
			  background: g.$bg-purpleLight;
			  border: none;
			}
			&::-webkit-scrollbar-thumb{
			  background: g.$baseColorLight;
			  border-radius: 10px;
			  box-shadow: none;
			}
		}
		&Menu1 {
			
		}
		&Menu3 {
            &__inner {
                border-top: none;
            }
			&__link {
				padding: 15px;
                &::before {
                    right: 15px;
                    left: inherit;
                }
				&._down_ {
                    &::before {
                        content: "\e313";
                    }
				}
			}
			&__dropdown {
				display: none;
				background: #EFEFEF;
				padding: 0;
			}
			&__subitem {
				pointer-events: inherit;
                border-bottom: 1px solid #CFCFCF;
                color: g.$text-black;
                .__ico {
                    color: g.$baseColor;
                }
                &:last-child {
                    border-bottom: none;
                }
			}
		}
		&Menu4 {
			padding: 20px 15px 15px;
			&__item {
				padding: 0 0 10px;
			}
		}
		&Menu5 {
			padding: 0 0 40px;
			margin-bottom: 100px;
		}
	}
}
