@charset "utf-8";
@use "../../../global/_" as g;

@include g.media-m-ov {
	.u-dp-m {
	}
}

@include g.media-s {
	.u-dp-m {
		display: none !important;
	}
}
