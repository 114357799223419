@charset "utf-8";
@use "../../global/_" as g;
.c-header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	&Logo {
		text-align: center;
		width: 300px;
		&__link {
			display: block;
			padding: 20px 30px;
			.__elem {
				width: 100%;
			}
		}
	}
}
@include g.media-m-ov {
	.c-header {

	}
}
@include g.media-m {
	.c-header {
        
	}
}
@include g.media-s {
	.c-header {
		width: 100%;
		&Logo {
			text-align: left;
			width: calc( 100% - 60px);
			&__link {
				display: flex;
				align-items: center;
				padding: 0 15px;
				height: 60px;
				.__elem {
					width: 180px;
				}
			}
		}
		&Logo {
		}
		._gnav-show_ & {
			@include g.bg-gradation-side(true);
			.c-headerLogo {
				background: none;
			}
		}
	}
}
