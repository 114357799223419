@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-pl-xxxl {
	padding-left: g.$m-xxxl !important;
	
	@include g.media-s {
		padding-left:math.div(g.$m-xxxl, 2) !important;

		&._sm-pl-0_    { padding-left: 0 !important; }
		&._sm-pl-xxxs_ { padding-left: g.$m-xxxs !important; }
		&._sm-pl-xxs_  { padding-left: g.$m-xxs !important; }
		&._sm-pl-xs_   { padding-left: g.$m-xs !important; }
		&._sm-pl-s_    { padding-left: g.$m-s !important; }
		&._sm-pl-m_    { padding-left: g.$m-m !important; }
		&._sm-pl-l_    { padding-left: g.$m-l !important; }
		&._sm-pl-xl_   { padding-left: g.$m-xl !important; }
		&._sm-pl-xxl_  { padding-left: g.$m-xxl !important; }
		&._sm-pl-xxxl_ { padding-left: g.$m-xxxl !important; }
	}
}
