@charset "utf-8";
@use "../../global/_" as g;
.c-snav {
	&Head {
        display: flex;
        align-items: center;
        width: 100%;
        height: 75px;
        @include g.bg-gradation-side(true);
        @include g.font-medium;
        color: g.$white;
        font-size: g.$fz-xl;
        padding: 0 15px;
        text-decoration: none;
	}
	&List {
		&__item {
			border: 1px solid g.$line-gray;
			border-top: none;
		}
		&__link {
			display: flex;
			align-items: center;
			padding: 15px;
			text-decoration: none;
            font-size: 1.5rem;
			
			.__txt {
				flex: 1 1 auto;
			}
			.__ico {
				flex: 0 0 auto;
				margin: 0 10px 0 0;
				font-size: g.$fz-xs;
                color: g.$baseColor;
			}
			&:hover {
				opacity: 1;
				background: rgba(g.$baseColor, .05);
			}
		}
	}	
}
@include g.media-s {
	.c-snav {
	}
}
