@charset "utf-8";
@use "../../global/_" as g;
.c-lnav {
	max-width: 1200px;
	width: 100%;
	margin: 60px auto 0;
	display: flex;
	flex-wrap: wrap;
	&._jc-c_ {
		justify-content: center;
	}
    &__item {
        border: 1px solid #DFDFDF;
    }
	&__link {
		background: #F2F2F2;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
        height: 50px;
		@include g.transition;
		padding: 10px 30px 10px 10px;
		font-size: 1.5rem;
		line-height: 1.4;
        &::before {
            @include g.centering-elements(false, true);
            right: 10px;
            font-family: 'Material Icons';
            content: "\e313";
            color: g.$baseColor;
            font-size: g.$fz-xl;
        }
	}
}

@include g.media-m-ov {
	.c-lnav {
		&__link {
			&:hover {
				background: #DFDFDF;
                color: g.$text-black;
			}
		}
		&._col2_,
        &._col4_ {
			.c-lnav__item {
				flex-basis: calc((100% - 21px) / 4);
				&:not(:nth-child(4n+1)) {
					margin-left: 7px;
				}
				&:not(:nth-child(-n+4)) {
					margin-top: 7px;
				}
			}
		}
		&._col3_ {
			.c-lnav__item {
				flex-basis: calc((100% - 14px) / 3);
				&:not(:nth-child(3n+1)) {
					margin-left: 7px;
				}
				&:not(:nth-child(-n+3)) {
					margin-top: 7px;
				}
			}
		}
	}
}

@include g.media-s {
	.c-lnav {
		.c-lnav__link {
			padding: 10px 20px 10px 10px;
		}
		&._col2_,
		&._col3_,
		&._col4_ {
			.c-lnav__item {
				flex-basis: calc((100% - 7px) / 2);
				@include g.iehack {
					flex-basis: calc((100% - 8px) / 2);
				}
				&:not(:nth-child(2n+1)) {
					margin-left: 7px;
				}
				&:not(:nth-child(-n+2)) {
					margin-top: 7px;
				}
			}
		}
		//スマホ強制1カラム -----------------------------------
		&._sp-col1_ {
			.c-lnav__item {
				flex: 0 0 100%;
				width: 100%;

				@include g.iehack {
					flex: 0 0 100%;
				}
				&:not(:nth-child(2n+1)) {
					margin-left: 0;
				}
				&:not(:nth-child(-n+1)) {
					margin-top: 7px;
				}
			}
		}
	}
}
