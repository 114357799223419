@charset "utf-8";
@use "../../../global/_" as g;
.c-index-bnr {
	&__body {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		margin-left: 9px;
		margin-right: 9px;
		width: calc((100% - 72px) / 4 );
	}
	&__link {
		position: relative;
		padding-top: 56.14%;
		overflow: hidden;
		display: block;
        box-shadow: 0 0 15px rgba(0,0,0,0.15);
		.__elem {
			width: 100%;
			@include g.centering-elements(true, false);
			top: 0;
            
		}
	}
}

@include g.media-m-ov {
	.c-index-bnr {
        &__link {
            @include g.transition;
            &._opacity_ {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
	}
}
@include g.media-s {
	.c-index-bnr {
		&.l-bg {
			padding: 30px 0;
		}
		.l-row__inner {
			padding-top: 0;
		}
		&__item {
			width: calc(50% - 10px);
			margin: 5px 5px;
		}
	}
}
