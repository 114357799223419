@charset "utf-8";
@use "../../global/_" as g;
.e-btn-arrow {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	min-width: 150px;
	height: 45px;
	margin: 10px 5px;
	padding: 0 30px;
	color: g.$white;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
    border-radius: 3px;
    z-index: 0;
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        left: 0;
        transition: 0.5s;
        @include g.bg-gradation-side(true);
    }
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;
        left: 0;
        @include g.bg-gradation-side(false);
    }
	.__ico {
        color: g.$white;
		@include g.centering-elements(false, true);
		font-size: g.$fz-s;
		&:first-child { left: 10px; }
		&:last-child { right: 10px; }
        &.icon-file-pdf-o {
            font-size: g.$fz-l;
        }
	}
	&:not(._disabled_) {
		cursor: pointer;
	}
	&._disabled_ {
		opacity: .5;
	}
	
	//サイズオプション Lサイズ-------------------------------
	&._w-l_ {
		min-width: 300px;
		height: 60px;
		font-size: g.$fz-m;
	}
	//サイズオプション Mサイズ-------------------------------
	&._w-m_ {
		min-width: 240px;
        height: 60px;
		font-size: g.$fz-m;
	}
	//サイズオプション Sサイズ-------------------------------
	&._w-s_ {
		min-width: inherit;
		height: 40px;
	}
    //カラー グレー-------------------------------
	&._gray_ {
		background: #F2F2F2;
        border: 1px solid #DFDFDF;
        color: g.$text-black;
        &::before,
        &::after {
            display: none;
        }
        .__ico {
            color: g.$baseColor;
        }
	}
}

@include g.media-m-ov {
	.e-btn-arrow {
		&:not(._disabled_):hover {
            color: g.$white;
            &::before {
			    opacity:0;
            }
		}
        &._gray_:hover {
            background: #DFDFDF;
            color: g.$text-black;
        }
	}
}

@include g.media-s {
	.e-btn-arrow {
		height: 50px;
		margin: 5px;
		font-size: g.$fz-m;
		
		.__ico {
			&:first-child { left: 10px; }
			&:last-child { right: 10px; }
		}
		
		//サイズオプション Lサイズ-------------------------------
		&._w-l_ {
			width: 85%;
			min-width: 0;
			height: 50px;
			margin: 10px 0;
		}
		//サイズオプション Mサイズ-------------------------------
		&._w-m_ {
			min-width: 70%;
			font-size: g.$fz-m;
		}
		//サイズオプション Sサイズ-------------------------------
		&._w-s_ {
			min-width: 0;
			min-height: 35px;
			padding: 0 20px;
			font-size: g.$fz-xxs;
		}
	}
}